import { useState, useContext, useEffect } from "react";

import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDBoilerPlate.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CRUDHeader.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import "../../../Components/Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import AppContext from "../../../AppContext/AppContext";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import ViewPayment from "./ViewPayment";
import { PAYMENT_TO_SELLER } from "../../../Utilities/APIs/APIs";
import CreatePayment from "./CreatePayment";
import ReactTooltip from "react-tooltip";
import { AiOutlineExclamationCircle } from "react-icons/ai";

function Payment() {
  //   const [search, setSearch] = useState("");
  const [payments, setPayments] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetPayments() {
      const { data } = await axiosInstance.get(PAYMENT_TO_SELLER);
      setPayments(data);
    }
    fetchAndsetPayments();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">Payment ({payments?.length} in total)</h1>
          {/* <div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="input_field"
              placeholder="Search"
              style={{ width: "400px" }}
            />
            <RiSearchLine style={{width: "30px",height: "30px",marginLeft: "-50px",marginBottom:"20px",color: "var(--white-2)",}}
            />
          </div> */}

          <CreateButton
            screenTopicSingular={"Payment To Seller"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Order" />
            <CRUDth th="Seller" />
            <CRUDth th="Amount" />
            {/* <CRUDth th="Created By" /> */}
            <CRUDth th="Date of Payment" />
            {/* <CRUDth th="Date of Approval" /> */}
            <CRUDth th="Status" />
          </CRUDTableHeader>
          <tbody>
            {payments?.map((payment) => (
              <CRUDTableRow key={payment?._id}>
                <ShortTextCell text={`#${payment?.order}`} />
                <ShortTextCell text={payment?.seller?.name} />
                <ShortTextCell text={payment?.amount} />
                {/* <ShortTextCell text={payment?.createdBy?.name} /> */}
                <ShortTextCell
                  text={new Date(payment?.dateOfPayment).toLocaleDateString()}
                />
                {/* {payment?.dateOfApproval ? (
                  <ShortTextCell
                    text={new Date(
                      payment?.dateOfApproval
                    ).toLocaleDateString()}
                  />
                ) : (
                  <td>Not Approved</td>
                )} */}
                {/* <ShortTextCell
                  text={
                    payment.isFailed
                      ? "Failed"
                      : payment?.isApproved
                      ? "Paid"
                      : "Pending"
                  }
                /> */}

                <ShortTextCell
                  text={
                    payment.isFailed ? (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        Failed
                        <AiOutlineExclamationCircle
                          data-tip={payment?.failedReason}
                          data-for="failed-tooltip"
                        />
                        <ReactTooltip
                          id="failed-tooltip"
                          place="top"
                          effect="solid"
                        />
                      </span>
                    ) : payment?.isApproved ? (
                      "Paid"
                    ) : (
                      "Pending"
                    )
                  }
                />

                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={payment._id}
                    setTargetID={setTargetID}
                  /> */}
                  {/* <CRUDButton
                    dataTip="Products"
                    dataFor="Products Item"
                    handleClick={() =>
                      navigate("/seller/sellerStore/products/" + store._id)
                    }
                  >
                    <RiImage2Line />
                  </CRUDButton>

                  <CRUDButton
                    dataTip="Orders"
                    dataFor="Order Item"
                    handleClick={() =>
                      navigate("/seller/sellerStore/orderScreen/" + store._id)
                    }
                  >
                    <RiShoppingCartLine />
                  </CRUDButton> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showCreateForm && `Create Payment`}
        >
          {showCreateForm && (
            <CreatePayment
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {/* {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Store Slider`}
          triggerFetch={triggerFetch}
        >
          <ViewPayment targetID={targetID} employee={employee} />
        </Modal>
      )} */}
    </>
  );
}

export default Payment;
